<template>
    <layout_main>
        <v-container>
            <p class="text-center" style="font-size: 32px; margin-bottom: 60px">版本更新日志</p>
            <div class="item" v-for="(i, num) in version_log">
                <div>
                    版本号: <span style="color: #575757">{{i.version}}</span>
                    <br />
                    更新日期: <span style="color: #575757">{{i.date}}</span>
                    <br />
                    调整总数: {{total(num)}} 个功能
                </div>
                <div v-if="i.add.length">
                    <div>新增功能:</div>
                    <div v-for="(ii) in i.add" style="margin-top: 10px">
                        <div style="color: #575757; text-indent: 40px">{{ ii.module_name }}</div>
                        <div style="text-indent: 40px; font-size: 14px; color: #888" v-for="(iii, index) in ii.list">
                            {{index + 1}}·{{iii}}
                        </div>
                    </div>
                </div>
                <div v-if="i.optimization.length">
                    <div>优化功能:</div>
                    <div v-for="(ii) in i.optimization" style="margin-top: 10px">
                        <div style="color: #575757; text-indent: 40px">{{ ii.module_name }}</div>
                        <div style="text-indent: 40px; font-size: 14px; color: #888" v-for="(iii, index) in ii.list">
                            {{index + 1}}·{{iii}}
                        </div>
                    </div>
                </div>
            </div>
        </v-container>
    </layout_main>
</template>
<script>
    export default {
        name: 'version',
        methods: {
            total(index) {
                const data = this.version_log[index]
                let count = 0
                for (let i of data.add) {
                    count += i.list.length
                }
                for (let i of data.optimization) {
                    count += i.list.length
                }
                return count
            }
        },
        data: () => ({
            version_log: [

                {
                    version: 'Beta 3.2.1',
                    date: '2021年11月15日',
                    add: [],
                    optimization: [
                        {
                          module_name: '航路冲突',
                          list: [
                              '点击解决冲突后显示完整航迹信息信息',
                          ]
                        },
                        {
                          module_name: '机场预览模块',
                          list: [
                              '调整航班数据',
                          ]
                        },
                    ]
                },{
                    version: 'Beta 3.2.0',
                    date: '2021年11月15日',
                    add: [],
                    optimization: [
                        {
                          module_name: '航路冲突',
                          list: [
                              '飞机信息速度修改为480kn',
                              '飞机航线一直显示',
                              '点击解决冲突后显示完整信息',
                          ]
                        },
                        {
                          module_name: '机场预览模块',
                          list: [
                              '甘特图的高度调增',
                              '调整航班数据',
                          ]
                        },
                    ]
                },
                {
                    version: 'Beta 3.1.0',
                    date: '2021年11月14日',
                    add: [],
                    optimization: [
                        {
                          module_name: '航路冲突',
                          list: [
                              '飞机信息显示速度',
                              '飞机信息显示方位角'
                          ]
                        },
                        {
                          module_name: '进离场模块',
                          list: [
                              '加入飞机动画指引',
                              '调整显示航迹逻辑'
                          ]
                        },
                        {
                            module_name: '机场预览模块',
                            list: [
                                '补充采集停机位数据',
                            ]
                        },
                        {
                            module_name: '扇区模块',
                            list: [
                                '在最后结果步骤中添加,飞机模拟飞行',
                                '修复扇区详情的显示问题',
                            ]
                        }
                    ]
                },
                {
                    version: 'Beta 3.0.0',
                    date: '2021年11月13日',
                    add: [
                        {
                            module_name: '航班模块',
                            list: [
                                '航班详情展示'
                            ],
                        },
                        {
                            module_name: '航路冲突模块',
                            list: [
                                '添加按钮"预测飞机航迹"和对应功能'
                            ]
                        }
                    ],
                    optimization: [
                        {
                            module_name: '机场预览模块',
                            list: [
                                '调整甘特图高度',
                                '调增甘特图的时间到竖线中间',
                                '降低飞机运行时速度'
                            ]
                        },
                        {
                            module_name: '航路冲突模块',
                            list: [
                                '将按钮"调用算法"改名为"冲突解决"',
                            ]
                        },
                        {
                            module_name: '机场模块',
                            list: [
                                '将左侧图片换为机场图片',
                            ]
                        },
                        {
                            module_name: '用户模块',
                            list: [
                                '将“添加分组”改为“添加角色”',
                            ]
                        },
                    ]
                },
                {
                    version: 'Beta 2.1.0',
                    date: '2021年11月12日',
                    add: [],
                    optimization: [
                        {
                            module_name: '用户管理模块',
                            list: [
                                '修改用户时候字段不正确的情况',
                                '添加用户的时候无法添加用户具体信息',
                                '添加用户的时候无法设置角色的问题',
                                '后端无法校验邮箱手机号的问题'
                            ]
                        },
                    ]
                },
            ]
        })
    }
</script>
<style lang="stylus">
    .item
        border-bottom dashed #ddd 1px
        padding-bottom 40px
        padding-top 40px
        /*margin-bottom: 80px*/
        &:last-child
            border none
</style>
